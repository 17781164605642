import { formatDateMixin } from '@mixins/format-date-mixin'
import { CopyRightText } from '@utils/constants'

function findNotCoveredPathId(array) {
  const indication = array.find(
    (item) => item.path === 'id' && item.listLogic?.startsWith('NOT')
  )

  return indication?.PathId
}

function compare(a, b) {
  if (a.path < b.path) return -1
  if (a.path > b.path) return 1
  return 0
}

function getNode(parent, pathId) {
  return parent?.ContentItems?.find((node) =>
    Array.isArray(node)
      ? node.find((n) => n?.PathId === pathId)
      : node?.PathId === pathId
  )
}

function findNode(rootNode, pathId) {
  const ancestors = pathId?.split('-')
  let node = rootNode
  for (let i = 0; i < ancestors.length; i++) {
    node = getNode(node, ancestors.slice(0, i + 1).join('-'))
  }

  return node
}

function findAncestors(currentNode, branch) {
  const nodes = []
  const ancestors = currentNode.PathId?.split('-')
  let prefix = ''

  ancestors.pop()
  ancestors.forEach((pathId) => {
    const path = prefix + (prefix === '' ? pathId : '-' + pathId)
    const node = getNode(branch, path)
    if (node !== undefined) {
      nodes.push(node)
      branch = node
      prefix = path
    }
  })

  return nodes
}

function findClinicalInput(clinicalInputs, PathId) {
  return clinicalInputs?.filter((input) =>
    input.matchingIndicationPaths.some(
      (path) => path === PathId && !input.exclude
    )
  )
}

function addIndication(nodeList, newNode, note, clinicalInputs) {
  const node = nodeList.find((x) => x.PathId === newNode.PathId)
  if (!node) {
    nodeList.push({
      path: newNode.path,
      uid: newNode.uid,
      text: newNode.text,
      listLogic: newNode.ListLogic,
      PathId: newNode.PathId,
      selected: false,
      note,
      clinicalInput: findClinicalInput(clinicalInputs, newNode.PathId),
      matchedIndication: newNode.autoIncluded || false,
    })

    return nodeList[nodeList.length - 1]
  }
  node.clinicalInput = findClinicalInput(clinicalInputs, newNode.PathId)
  return node
}

function addNote(notes, newNode) {
  const node = notes.find((x) => x.node.PathId === newNode.node.PathId)
  if (!node) {
    notes.push(newNode)
  }
}

function hasSelectedIndications(indications) {
  return indications !== undefined && indications.length > 0
}

function hasNotes(notes) {
  return notes !== undefined && notes.length > 0
}

export function buildClinicalInput(clinicalInput, tabString) {
  let clinicalInputString =
    tabString === ''
      ? tabString
      : tabString +
        tabString +
        clinicalInput.name +
        tabString +
        formatDateMixin.methods.convertIsoToDateTime(clinicalInput.dateTime) +
        tabString
  if (clinicalInput.value !== undefined) {
    clinicalInputString += clinicalInput.value + ' ' + clinicalInput.unit
  }
  clinicalInputString += '\n'
  return clinicalInputString
}

function addSelectedIndicationsAncestors(
  documentedIndications,
  rootNodes,
  clinicalInputs,
  allIndicationPaths
) {
  if (hasSelectedIndications(documentedIndications)) {
    documentedIndications.forEach((x) => {
      const ancestors = findAncestors(x.node, rootNodes)
      ancestors.forEach((node) =>
        Array.isArray(node)
          ? node.forEach((n) =>
              addIndication(allIndicationPaths, n, '', clinicalInputs)
            )
          : addIndication(allIndicationPaths, node, '', clinicalInputs)
      )
      const indication = addIndication(
        allIndicationPaths,
        x.node,
        '',
        clinicalInputs
      )
      indication.selected = true
    })
  }
}

function addSelectedIndicationAncestorNotes(
  documentedIndicationNotes,
  rootNodes,
  clinicalInputs,
  allIndicationPaths
) {
  if (hasNotes(documentedIndicationNotes)) {
    documentedIndicationNotes.forEach((element) => {
      const indication = allIndicationPaths.find(
        (x) => x.PathId === element.node.PathId
      )

      if (indication !== undefined) {
        indication.note = element.note
      } else {
        handleUndefinedIndication(
          element,
          rootNodes,
          allIndicationPaths,
          clinicalInputs
        )
      }
    })
  }
}

function handleUndefinedIndication(
  element,
  rootNodes,
  allIndicationPaths,
  clinicalInputs
) {
  if (element.note?.trim() !== '' || element.node.autoIncluded) {
    const ancestors = findAncestors(element.node, rootNodes)
    ancestors.forEach((node) =>
      addIndication(allIndicationPaths, node, '', clinicalInputs)
    )
    addIndication(
      allIndicationPaths,
      element.node,
      element.note,
      clinicalInputs
    )
  }
}

function sortNotCoveredLogic(allIndicationPaths) {
  const notCovered = findNotCoveredPathId(allIndicationPaths)
  if (notCovered) {
    const first = allIndicationPaths.filter(
      (item) => !item.PathId.includes(notCovered)
    )
    const second = allIndicationPaths.filter((item) =>
      item.PathId.includes(notCovered)
    )

    allIndicationPaths = [...first.sort(compare), ...second.sort(compare)]
  } else {
    allIndicationPaths.sort(compare)
  }
}

export function previewIndicationsNodes(
  documentedIndications,
  documentedIndicationNotes,
  documentedGuidelines,
  clinicalInputs,
  rootNodes
) {
  const previewDocumentationNodes = []

  documentedGuidelines?.forEach((guideline) => {
    const indications = documentedIndications?.filter(
      (x) =>
        x.code === guideline?.code &&
        x.guidelineHsim === guideline?.guidelineHsim
    )

    const notes =
      documentedIndicationNotes[guideline?.code]?.guidelineHsim ===
      guideline?.guidelineHsim
        ? documentedIndicationNotes[guideline?.code]?.nodes
        : []

    const matchingClinicalInputs = clinicalInputs?.filter(
      (input) => input.matchingIndicationPaths?.length > 0
    )

    // Auto-include indications that have matching clinical inputs
    matchingClinicalInputs?.forEach((input) => {
      if (!input.exclude) {
        input.matchingIndicationPaths?.forEach((path) => {
          const node = findNode(rootNodes[guideline.code], path)
          if (node !== undefined) {
            node.autoIncluded = true
            addNote(notes, { node, note: '' })
          }
        })
      }
    })

    const allIndicationPaths = previewIndicationsNodesInternal(
      indications,
      notes,
      clinicalInputs,
      rootNodes[guideline.code]
    )
    sortNotCoveredLogic(allIndicationPaths)
    const previewSummaryItem = previewDocumentationNodes?.find(
      (x) =>
        x.guidelineHsim === guideline?.guidelineHsim &&
        x.code === guideline?.code
    )

    if (previewSummaryItem !== undefined) {
      previewSummaryItem.allIndicationPaths = allIndicationPaths
    } else {
      previewDocumentationNodes.push({
        code: guideline.code,
        guidelineHsim: guideline?.guidelineHsim,
        contentEdition: guideline?.contentEdition,
        allIndicationPaths,
      })
    }
  })
  return previewDocumentationNodes
}

function addContentEditionPostfix(contentEdition) {
  if (contentEdition !== undefined) {
    const majorVersion = contentEdition?.substr(
      0,
      contentEdition.indexOf('.') > -1
        ? contentEdition.indexOf('.')
        : contentEdition.length
    )
    let postFix = ''
    const lastDigit = majorVersion?.slice(-1)
    switch (lastDigit) {
      case '1':
        postFix = 'st'
        break
      case '2':
        postFix = 'nd'
        break
      case '3':
        postFix = 'rd'
        break
      default:
        postFix = 'th'
        break
    }
    return ' ' + majorVersion + postFix + ' Edition.'
  }
  return ''
}

export function previewIndicationsNodesInternal(
  documentedIndications,
  documentedIndicationNotes,
  clinicalInputs,
  rootNodes
) {
  const allIndicationPaths = []

  addSelectedIndicationsAncestors(
    documentedIndications,
    rootNodes,
    clinicalInputs,
    allIndicationPaths
  )
  addSelectedIndicationAncestorNotes(
    documentedIndicationNotes,
    rootNodes,
    clinicalInputs,
    allIndicationPaths
  )

  if (allIndicationPaths !== undefined) {
    return allIndicationPaths
  }
  return []
}

export function sortDocumentedGuidelines(guidelines, sortOrder) {
  if (!Array.isArray(sortOrder)) return guidelines

  const result = guidelines?.sort(function (guidelineA, guidelineB) {
    const indexA = sortOrder.indexOf(guidelineA.code)
    const indexB = sortOrder.indexOf(guidelineB.code)
    if (indexA < indexB) {
      return -1
    }
    if (indexA > indexB) {
      return 1
    }
    return 0
  })

  return result
}

export function isGuidelineDocumented(
  documentedGuidelines,
  code,
  guidelineHsim
) {
  return (
    documentedGuidelines?.findIndex(
      (i) => i.code === code && i.guidelineHsim === guidelineHsim
    ) > -1
  )
}

export function documentedGuidelineCountByCode(documentedGuidelines, code) {
  const filtered = documentedGuidelines?.filter((g) => g.code === code)
  return filtered?.length || 0
}

export function removeDocumentedGuideline(
  documentedGuidelines,
  documentedIndicationNotes,
  documentedIndications,
  overallStatus,
  code
) {
  const indexOfDocumentedGuideline = documentedGuidelines?.findIndex(
    (g) => g.code === code
  )

  if (indexOfDocumentedGuideline > -1) {
    documentedGuidelines.splice(indexOfDocumentedGuideline, 1)
  }

  documentedIndications = documentedIndications.filter(function (obj) {
    return obj.code !== code
  })

  if (
    documentedIndicationNotes &&
    documentedIndicationNotes[code] !== undefined
  ) {
    // loop through all nodes and set node.note to ''
    documentedIndicationNotes[code].nodes.forEach((node) => {
      node.note = ''
    })
    delete documentedIndicationNotes[code]
  }

  if (Array.isArray(overallStatus)) {
    overallStatus = overallStatus.filter(function (obj) {
      return obj.code !== code
    })
  }

  return {
    documentedGuidelines,
    documentedIndicationNotes,
    documentedIndications,
    overallStatus,
  }
}

export function guidelineCopyRightText(contentEdition) {
  return CopyRightText.replace('{{}}', addContentEditionPostfix(contentEdition))
}

export function generateGuidelineSummary(node) {
  const nodeDepth = `${node.path.split('_').length * 10}px`
  const clearText = clearNodeText(node.text)
  const indicationSelected = node.selected ? '☒' : '☐'
  const hasClinicalInput = node?.clinicalInput && node.clinicalInput.length > 0

  let summary = `<div style="margin-left: ${nodeDepth};">`
  summary += generateNodeSummary(node, clearText, indicationSelected)

  if (hasClinicalInput) {
    summary += generateClinicalData(node)
  }

  if (node.note && node.note.length !== 0) {
    summary += generateNodeNote(node, hasClinicalInput)
  }

  summary += `</div>`
  return summary
}

export function clearNodeText(text) {
  return text.replace(/\[\[.*?\]\]/g, '').replace(/\w+\s+Calculator/gi, '')
}

export function generateNodeSummary(node, clearText, indicationSelected) {
  const indication = node.matchedIndication
    ? `<strong>${clearText}</strong>`
    : `<span>${clearText}</span>`
  return `<div>${indicationSelected}${indication}</div>`
}

export function generateClinicalData(node) {
  let clinicalData = `<div class="clinical-data"><strong><em>Supporting Clinical Data:</em></strong>`
  node.clinicalInput.forEach((data) => {
    clinicalData += generateClinicalDataItem(data)
  })
  clinicalData += `</div>`
  return clinicalData
}

export function generateClinicalDataItem(clinicalData) {
  const dateTimeClass = clinicalData.dateTime ? 'border-right' : ''
  const valueClass = clinicalData.value ? 'border-right px-2 py-1' : ''
  const dateTime = formatDateMixin.methods.convertIsoToDateTime(
    clinicalData.dateTime
  )
  const value = clinicalData.value
    ? `<span class="px-2 py-1">${clinicalData.value}${clinicalData.unit}</span>`
    : ''
  return `<div><span class="${dateTimeClass}">${clinicalData.name}</span><span class="${valueClass}">${dateTime}</span>${value}</div>`
}

export function generateNodeNote(node, hasClinicalInput) {
  const marginTop = hasClinicalInput ? '5px' : '0'
  return `<div style="margin-top: ${marginTop}; margin-bottom: 10px;"><span class="note">${htmlEncode(
    node.note || ''
  )}</span></div>`
}

export function htmlEncode(input) {
  return input
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
}

export function findIndexes(arr, condition) {
  const indexesMap = new Map()

  arr.forEach((element, index) => {
    if (condition(element)) {
      indexesMap.set(element.code, index)
    }
  })

  return Array.from(indexesMap.values())
}

export function deleteIndexes(arr, indexesToDelete) {
  indexesToDelete.sort((a, b) => b - a)
  indexesToDelete.forEach((index) => {
    arr.splice(index, 1)
  })
}

export function defaultCode(code, guidelineHsim) {
  return code === undefined || code === '' ? guidelineHsim : code
}

export function findIndicationIndex(indications, node, code, guidelineHsim) {
  return indications?.findIndex((ind) =>
    matchesIndication(ind, node, code, guidelineHsim)
  )
}

function matchesIndication(ind, node, code, guidelineHsim) {
  const nodeMatch = Array.isArray(ind.node)
    ? ind.node.find((n) => n.PathId === node.PathId && n.id === node.id)
    : ind.node?.PathId === node.PathId && ind.node?.id === node.id

  return ind.code === code && ind.guidelineHsim === guidelineHsim && nodeMatch
}

export function handleSelectionStatus(params) {
  const {
    s,
    index,
    status,
    node,
    code,
    guidelineHsim,
    userSelected,
    clinicalInput,
  } = params
  if (status === undefined) {
    handleUndefinedStatus(
      s,
      index,
      node,
      code,
      guidelineHsim,
      userSelected,
      clinicalInput
    )
  } else if (status === false) {
    if (index > -1) {
      deleteIndicationsAt(s, index)
    }
  } else if (status === true) {
    addIndicationIfNotExists(
      s,
      index,
      node,
      code,
      guidelineHsim,
      userSelected,
      clinicalInput
    )
  }
}

function handleUndefinedStatus(
  s,
  index,
  node,
  code,
  guidelineHsim,
  userSelected,
  clinicalInput
) {
  if (index > -1) {
    s.toggleIndicationSelected = false
    deleteIndicationsAt(s, index)
  } else {
    s.documentedIndications.push({
      node,
      code,
      guidelineHsim,
      userSelected,
      clinicalInput,
    })
    s.toggleIndicationSelected = true
  }
}

function addIndicationIfNotExists(
  s,
  index,
  node,
  code,
  guidelineHsim,
  userSelected,
  clinicalInput
) {
  if (index === -1) {
    s.documentedIndications.push({
      node,
      code,
      guidelineHsim,
      userSelected,
      clinicalInput,
    })
  }
}

function deleteIndicationsAt(s, index) {
  s.documentedIndications.splice(index, 1)
}
