import axios from 'axios'
import { buildModel } from '@/src/reasoningModel/SynapseModelBuilder'
import { buildCompositeHsim } from '@utils/composite-hsim'
import {
  collectIndications,
  collectClinicalInput,
} from '@utils/guideline-data-helper'
import { notCoveredIndication } from '@utils/constants'

const cache = new Map()

function buildSynapseModel(data) {
  const response = buildModel(data)
  response.guidelineInfo = {
    codifiedRelease: 'No Content',
    contentEdition: data.meta.contentVersion,
    hsim: '',
  }

  response.guideline = { ContentItems: [] }
  response.indications = []
  response.matchedIndications = []

  return response
}

function handleResponse(commit, selectedGuideline, data, hsim) {
  cache.set(hsim, data)
  if (!state.interruptRequest.includes(hsim.substr(3))) {
    const guidelineData = data
    guidelineData.guidelineInfo.associatedCode =
      selectedGuideline?.associatedCode || guidelineData?.guidelineInfo?.hsim
    guidelineData.guidelineInfo.guidelineTitle =
      selectedGuideline?.guidelineTitle
    guidelineData.guidelineInfo.codeType = selectedGuideline?.codeType || ''
    commit('setGuidelineData', guidelineData)
    commit('updateClinicalInputs')
    commit('setGuidelineError', null)
    return guidelineData
  } else {
    commit('setReady', true)
  }
}

function getLeafNodes(matchedIndications, indications) {
  return (
    matchedIndications?.filter(({ PathId }) => {
      const i = indications.get(PathId)
      return i?.ContentItems == null || i.ContentItems.length === 0
    }) ?? []
  )
}

function addNonLeafMatches(matchedIndications, clinicalMatches) {
  matchedIndications?.forEach((indication) => {
    if (
      !clinicalMatches.includes(indication.PathId) &&
      indication.concepts?.some(hasClinicalData)
    ) {
      clinicalMatches.push(indication.PathId)
    }
  })
}

function hasClinicalData(concept) {
  return concept.clinicalInput?.length > 0
}

export const state = {
  guidelineData: {},
  guidelineError: null,
  clinicalMatches: [],
  highlightedIndications: [],
  circleIndications: [],
  metStatus: {},
  clinicalInputs: [],
  categorizedClinicalInput: {},
  notCovered: false,
  interruptRequest: [],
  excludeFromAuthRequest: [],
  showClinicalNote: {},
}

export const getters = {
  guideline: (s) => s.guidelineData,
  guidelineError: (s) => s.guidelineError,
  clinicalMatches: (s) => s.clinicalMatches,
  highlightedIndications: (s) => s.highlightedIndications,
  circleIndications: (s) => s.circleIndications,
  guidelineMetStatuses: (s) => s.metStatus,
  clinicalInputs: (s) => s.clinicalInputs,
  categorizedClinicalInput: (s) => s.categorizedClinicalInput,
  notCoveredIndication: (s) => s.guidelineData.notCoveredIndication,
  notCovered: (s) => s.notCovered,
  hasNotCovered: (s) => !!s.guidelineData.notCoveredIndication,
  excludeFromAuthRequest: (s) => s.excludeFromAuthRequest,
  clinicalNotesBinary: (s) => (id) => {
    const note = s.categorizedClinicalInput.clinicalNotes.find(
      (n) => n.id === id
    )

    return note?.content[0]?.attachment?.data
      ? window.atob(note?.content[0]?.attachment?.data, 'binary')
      : null
  },
  showClinicalNote: (s) => (id) => {
    return s.showClinicalNote[id]
  },
}

export const mutations = {
  setReady(s, newValue) {
    if (s.guidelineData) {
      s.guidelineData.ready = newValue
    }
  },
  resetSelections(s) {
    Object.assign(s, {
      metStatus: [],
      notCovered: false,
      showClinicalNote: {},
    })
  },
  setGuidelineError(s, newValue) {
    s.guidelineError = newValue?.response?.data || null
  },
  setGuidelineData(s, newValue) {
    if (newValue === null) {
      return
    }

    s.guidelineData = newValue
    const { guideline, matchedIndications, clinicalInput, indications } =
      s.guidelineData

    const allIndications = collectIndications(guideline)
    const indicationsByPath = new Map(allIndications?.map((i) => [i.PathId, i]))
    const leafIndications = getLeafNodes(matchedIndications, indicationsByPath)

    s.clinicalInputs = collectClinicalInput(clinicalInput)
    s.clinicalMatches = s.clinicalInputs.flatMap(
      (c) => c.matchingIndicationPaths
    )
    addNonLeafMatches(s.clinicalInputs, s.clinicalMatches)

    s.highlightedIndications = matchedIndications.map((i) => i.PathId)
    s.circleIndications = []

    const queue = [...leafIndications]
    while (queue.length) {
      const node = queue.shift()
      const parentPathEnd = node.PathId?.lastIndexOf('-')
      if (parentPathEnd != null && parentPathEnd !== -1) {
        const parentPath = node.PathId.substring(0, parentPathEnd)
        const parentIndication = indicationsByPath.get(parentPath)
        if (parentIndication != null) {
          s.circleIndications.push(parentIndication.PathId)
          queue.push(parentIndication)
        }
      }
    }

    s.categorizedClinicalInput = clinicalInput

    if (indications?.Content?.length > 0) {
      const filteredData = indications.Content.filter(
        (item) => item?.PathId !== ''
      )
      if (filteredData.length > 0) {
        s.guidelineData.notCoveredIndication = filteredData.filter(
          (ContentItem) =>
            ContentItem?.ListLogic?.toLowerCase() ===
            notCoveredIndication?.toLowerCase()
        )

        s.guidelineData.guideline.ContentItems = filteredData.filter(
          (ContentItem) =>
            ContentItem?.ListLogic?.toLowerCase() !==
            notCoveredIndication?.toLowerCase()
        )
        s.guidelineData.guideline.ContentItems.forEach((component, index) => {
          component.id = `coveredIndication${index}`
        })
        s.guidelineData.notCoveredIndication.forEach((component, index) => {
          component.id = `notCoveredIndication${index}`
        })
      }
    }

    if (s.guidelineData) {
      s.guidelineData.ready = true
    }
  },
  setMetStatus(s, pair) {
    s.metStatus[pair.id] = pair.met
  },
  setNotCovered(s, newValue) {
    s.notCovered = newValue
  },
  setClinicalNoteBinaryData(s, { id, data }) {
    const content = s.categorizedClinicalInput.clinicalNotes.find(
      (note) => note.id === id
    ).content[0]
    content.attachment.data = data
  },
  addRequestInterrupt(s, hsim) {
    s.interruptRequest.push(hsim)
  },
  clearRequestInterrupt(s, hsim) {
    const index = s.interruptRequest.indexOf(hsim)
    if (index !== -1) {
      s.interruptRequest.splice(index, 1)
    }
  },
  setIgnoreClinicalData(s, newValue) {
    s.excludeFromAuthRequest = newValue
  },
  updateClinicalInputs(s) {
    s.clinicalInputs.forEach((c) => {
      const found = s.excludeFromAuthRequest.find(
        (resource) =>
          resource.clinicalInputId === c.clinicalInputId ||
          resource.clinicalInputId === c.clinicalInputKey
      )
      c.exclude = !!found
    })
  },
  setShowClinicalNote(s, { id, value }) {
    s.showClinicalNote[id] = value
  },
}

export const actions = {
  retrieveGuideline({ commit }, { sessionId, selectedGuideline }) {
    commit('resetSelections')
    commit('setGuidelineData', null)
    commit('setGuidelineError', null)
    const hsim = buildCompositeHsim(
      selectedGuideline?.product,
      selectedGuideline?.hsim
    )
    const params = new URLSearchParams({
      session: sessionId,
      hsim,
      contentVersion: selectedGuideline?.edition,
    })

    const data = cache.get(hsim)
    if (data) {
      return handleResponse(commit, selectedGuideline, data, hsim)
    }
    const noGuideline = selectedGuideline.guidelineTitle
      .toLowerCase()
      .includes('no guideline')
    const endPoint = `/api/Dtr/${
      noGuideline ? 'NoGuideline' : 'Guideline'
    }?${params.toString()}`

    return axios
      .get(endPoint, {})
      .then((response) => {
        if (noGuideline) {
          response.data = buildSynapseModel(response.data)
        }

        return handleResponse(commit, selectedGuideline, response.data, hsim)
      })
      .catch((error) => {
        commit('setGuidelineError', error)
        return error
      })
  },
  setMetStatus({ commit }, { action, id }) {
    commit('setMetStatus', { id, met: action })
  },
  setNotCovered({ commit }, value) {
    commit('setNotCovered', value)
  },
  setClinicalNotesBinaryData({ commit }, { id, data }) {
    commit('setClinicalNoteBinaryData', { id, data })
  },
  resetGuidelineData({ commit }) {
    commit('resetSelections')
  },
  addRequestInterrupt({ commit }, hsim) {
    commit('addRequestInterrupt', hsim)
  },
  clearRequestInterrupt({ commit }, hsim) {
    commit('clearRequestInterrupt', hsim)
  },
  setExcludeFromAuthRequest({ commit }, data) {
    commit('setIgnoreClinicalData', data)
    commit('updateClinicalInputs')
  },
  setReady({ commit }, newValue) {
    commit('setReady', newValue)
  },
  setShowClinicalNote({ commit }, { id, value }) {
    commit('setShowClinicalNote', { id, value })
  },
}
