import axios from 'axios'

export const state = {
  searchData: {},
  selectedGuideline: null,
  guidelineSearchError: null,
}

export const getters = {
  searchData: (s) => s.searchData,
  selectedGuideline: (s) => s.selectedGuideline,
  guidelineSearchError: (s) => s.guidelineSearchError,
  guidelineSearchErrorCode: (s) =>
    s.guidelineSearchError?.Extensions?.errorCode,
  allCodesHaveDocumentation: (s) => {
    const notDocumented = s.searchData.filter((row) => {
      return (
        row.searchOutput.searchResults.length > 0 && !this.hasDocumentation(row)
      )
    })

    return notDocumented.length === 0
  },
}

export const mutations = {
  SEARCH_DATA(s, newValue) {
    const codes = newValue.map((item) => item.searchOutput.guidelineCode)
    s.searchData = newValue.sort(function (code1, code2) {
      const indexA = codes.indexOf(code1.searchOutput.guidelineCode)
      const indexB = codes.indexOf(code2.searchOutput.guidelineCode)
      if (indexA < indexB) {
        return -1
      }
      if (indexA > indexB) {
        return 1
      }
      return 0
    })
  },
  SELECT_GUIDELINE(s, newValue) {
    s.selectedGuideline = newValue
    const selectedGuideline = s.searchData?.find((row) => {
      return row.searchOutput.guidelineCode === newValue?.associatedCode
    })
    if (selectedGuideline) {
      s.selectedGuideline.codeType = selectedGuideline.rulesOutput.searchType
    }
  },
  GUIDELINE_SEARCH_ERROR(s, newValue) {
    s.guidelineSearchError = newValue?.response?.data || null
  },
}

export const actions = {
  async search({ commit }, { sessionId, nonce }) {
    try {
      const response = await axios.get(
        `/api/Dtr/GuidelineSearch?session=${sessionId}&nonce=${nonce}`,
        { withCredentials: true }
      )
      const searchData = response.data
      commit('SEARCH_DATA', searchData)
      commit('GUIDELINE_SEARCH_ERROR', null)
      return searchData
    } catch (error) {
      commit('GUIDELINE_SEARCH_ERROR', error)
      return error
    }
  },
  async selectGuideline({ commit }, { selectedGuideline }) {
    await commit('SELECT_GUIDELINE', selectedGuideline)
    return selectedGuideline
  },
  async launchSession(_, { sessionId }) {
    try {
      const response = await axios.get(`/api/Dtr/LaunchSession/${sessionId}`, {
        withCredentials: true,
      })
      window.location.href = response.request.responseURL
    } catch (error) {
      console.error('launchSession_ERROR', error)
    }
  },
}
