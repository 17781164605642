export function collectIndications(indication, indicationList = []) {
  if (indication?.ContentItems == null) {
    return indicationList
  }

  indicationList.push(indication)

  for (const child of indication.ContentItems) {
    collectIndications(child, indicationList)
  }
  return indicationList
}

export function normalizeClinicalInput(clinicalInput) {
  return {
    clinicalInputId:
      clinicalInput?.clinicalInputId ?? clinicalInput.clinicalInputId,
    clinicalInputKey:
      clinicalInput?.clinicalInputKey ?? clinicalInput.clinicalInputKey,
    value: clinicalInput?.Value ?? clinicalInput?.value,
    unit: clinicalInput?.unit ?? clinicalInput?.Units,
    dateTime: clinicalInput?.DateTime ?? clinicalInput?.dateTime,
    name: clinicalInput?.text ?? clinicalInput?.Name,
    matchingIndicationPaths:
      clinicalInput?.matchingIndicationPaths ??
      clinicalInput.matchingIndicationPaths ??
      [],
  }
}

function uniqBy(a, key) {
  const index = []
  return a?.filter((item) => {
    const k = key(item)
    if (index.indexOf(k) >= 0) {
      return false
    } else {
      index.push(k)
      return true
    }
  })
}

const categories = [
  'problemCodes',
  'procedureCodes',
  'labdata',
  'vitalSigns',
  'medicationAdministrations',
]

export function collectClinicalInput(clinicalInput) {
  const clinicalMatches = []
  categories.forEach((category) => {
    if (clinicalInput?.[category]) {
      Object.values(clinicalInput[category]).forEach((clinicalInputItem) => {
        if (clinicalInputItem.matchingIndicationPaths) {
          clinicalMatches.push(clinicalInputItem)
        }
      })
    }
  })

  const matches = clinicalMatches.map(normalizeClinicalInput)
  return uniqBy(matches, (x) => x.clinicalInputId)
}
